import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"





const LpPage = () => (
<div>
<Seo
title="ランディングページを33,000円から制作します。 | Bgreen LP格安・短納期制作サービス"
description="ランディングページ作成サービス。テンプレートでの制作や様々なジャンルのLPの短納期・格安制作。またデザインのみ、コーディングのみの案件も相場より安い料金で承っているのでおすすめです。LPOのリスティング運用代行も展開しております。"
/>
<Layout></Layout>



</div>
  
)

export default LpPage
